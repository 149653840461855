@import url('@livechat/design-system/dist/design-system.css');

html,
body {
  padding: 0;
  margin: 0;
  font-family: sans-serif;
}

footer {
  padding: 16px;
  display: flex;
  flex-direction: row;
  text-align: center;
}

.root-elem {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

* {
  box-sizing: border-box;
}

.full-screen-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.view-container {
  padding: 16px;
}

.agents-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.center-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
}
